/* The following block can be included in a custom.scss */

/* make the customizations */
$themeColor: rgba(255, 102, 0, 0.911);
$dark-bg: #101B2C;
$roboto: 'Lato', sans-serif;
$satoshi: 'Satoshi', sans-serif;
$inter: 'Inter', sans-serif;
$popins: 'Poppins', sans-serif;

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

// fonts
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
@import url('https://api.fontshare.com/v2/css?f[]=satoshi@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

// Bootstrap overrides
.btn-primary {
  --bs-btn-color: #EEF1FD;
  --bs-btn-bg: #4167DA;
  --bs-btn-border-color: #4167DA;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #315cdb;
  --bs-btn-hover-border-color: #315cdb;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #315cdb;
  --bs-btn-active-border-color: #315cdb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #4167DA;
  --bs-btn-disabled-border-color: #4167DA;
}

// Customs
.custom-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  gap: 10px;
  min-width: 110px;
  min-height: 48px;
  background: linear-gradient(0deg, #F0F4FF, #F0F4FF), #FFFFFF;
  border-radius: 12px;

  font-family: $roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #3654AF;

  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.custom-box {
  background: #EDF6FF;
  border-radius: 32px;
}


body {
  --bs-body-font-family: #{$roboto, var(--bs-font-sans-serif)};

  .App {
    transition: all .3s;
    background-color: #FBFDFF;
    position: relative;

    .timer {
      display: flex;
      justify-content: center;
      background-color: #333;
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 10px;
      color: #fff;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);

      .bloc-timer {
        text-align: center;
        margin: 0 10px;
        border-radius: 5px;
        background-color: #444;
        padding: 10px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
        min-width: 60px;

        .nombre {
          font-size: 2em;
          color: #fff;
        }

        .label {
          font-size: 10px;
          color: #ccc;
          text-transform: uppercase;
        }
      }
    }


    header {
      position: sticky;
      top: 0;
      background-color: $white;
      padding: 5px 0;
      z-index: 3;

      .header-wrapper {
        display: flex;
        align-items: center;

        .logo {
          a {
            text-decoration: none;
            display: flex;
            align-items: center;
            gap: 10px;

            .logo-content {
              .title {
                font-family: $satoshi;
                font-style: normal;
                font-weight: 900;
                font-size: 18px;
                line-height: 24px;
                color: #02264C;
              }

              .sub-title {
                font-family: $roboto;
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 18px;
                color: #68798B;
              }
            }
          }

          img {
            max-width: 60px;
            border: 2px solid #eef1fd;
            border-radius: 50%;
          }
        }

        .locale-theme {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 22px;

          .locale-changer {
            .dropdown-toggle {
              box-shadow: none;
              outline: none;
              border: none;
            }

            .flag {
              width: 40%;
            }
          }
        }
      }
    }

    .banner {
      background-image: url('../public/assets/images/pxfuel.jpg');
      min-height: calc(634px - 90px);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      text-align: center;

      font-family: $satoshi;
      font-style: normal;
      color: #EAF6FF;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center bottom;

      h1 {
        font-weight: 900;
        font-size: 48px;
        line-height: 56px;
        margin-bottom: 20px;
      }

      h2 {
        font-weight: 400;
        font-size: 36px;
        line-height: 42px;
        margin-bottom: 20px;
      }

      .btn {
        font-family: $roboto, var(--bs-font-sans-serif);
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .plans {
      position: relative;
      top: -80px;
      color: #02264C;
      margin-top: 3rem;

      .custom-box {
        .title-area {
          text-align: center;
          padding: 32px 20px 20px 20px;

          h3 {
            font-family: $satoshi;
            font-style: normal;
            font-weight: 900;
            font-size: 36px;
            line-height: 48px;
          }

          h4 {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            color: #68798B;
          }
        }

        .billed-mode {
          background: #E1F0FE;
          border-radius: 12px;
          width: 280px;
          margin: auto;
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 4px;

          .mode {
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            font-family: $inter;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;

            width: 132px;
            height: 40px;

            cursor: pointer;

            &:not(.active) {
              color: #68798B;
            }

            &.active {
              background: #CFE7FD;
            }
          }
        }

        .plan-items-wrapper {
          margin-top: 32px;
          transition: all .3s;
          padding: 10px;

          .plan-item {
            background: #EDF6FF;
            border: 1px solid #B8D9F7;
            border-radius: 20px;
            padding: 32px;
            margin-top: 10px;

            .team-size {
              margin-bottom: 14px;

              img {
                margin-right: 12px;
                height: 50px;
              }

              span {
                font-family: $roboto;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #02264C;
              }
            }

            .title {
              margin-bottom: 14px;

              h5 {
                font-family: $satoshi;
                font-style: normal;
                font-weight: 900;
                font-size: 36px;
                line-height: 48px;
                margin-bottom: 0;
              }
            }

            .prices {
              margin-bottom: 28px;

              ul {
                gap: 10px;
                flex-wrap: wrap;

                li {
                  font-family: $roboto;
                  font-style: normal;
                  font-weight: 600;
                  //font-size: 14px;

                  line-height: 20px;

                  &:not(.active) {
                    color: #68798B;
                    font-size: 150%;

                    b {
                      text-decoration: line-through;
                    }
                  }

                  &.active {
                    color: #02264C;
                    font-size: 200%;
                  }

                  &.discount-badge {
                    background: #E1F0FE;
                    border: 1px solid #B8D9F7;
                    border-radius: 8px;
                    padding: 6px 12px;
                    font-family: $inter;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 20px;
                    text-align: center;
                    color: #3654AF;
                    text-decoration: none;
                  }
                }
              }
            }

            .includes {
              margin-bottom: 1rem;

              ul {
                li {
                  &:not(:last-child) {
                    margin-bottom: 24px;
                  }

                  svg {
                    margin-right: 16px;
                  }

                  span {
                    font-family: $roboto;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #02264C;
                  }
                }
              }
            }

            .action {
              .btn {
                font-family: $roboto;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #3654AF;
                background: #D9E2FB;
                border-radius: 12px;
                border: none;
                padding: 16px;
              }

              .condition {
                margin-top: 5px;
                color: #565454

              }
            }

            &:hover,
            &.active {
              background: #E1F0FE;

              .action {
                .btn {
                  color: #EEF1FD;
                  background: #4167DA;
                }
              }

            }
          }

          @include media-breakpoint-down(md) {
            padding: 10px;
          }
        }
      }
    }

    .features {
      .title-area {
        text-align: center;

        h3 {
          font-family: $satoshi;
          font-style: normal;
          font-weight: 900;
          font-size: 36px;
          line-height: 48px;
        }

        h4 {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
          color: #68798B;
        }
      }

      .feature-items-wrapper {
        margin-top: 52px;

        .feature-item {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          flex-flow: column;

          .icon-box {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #EDF6FF;
            width: 64px;
            height: 64px;
            border-radius: 100%;
            margin-bottom: 12px;
          }

          .content {
            h6 {
              font-family: $roboto;
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 24px;
              text-align: center;
              color: #02264C;
              margin-bottom: 8px;
            }

            p {
              font-family: $roboto;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              text-align: center;
              color: #02264C;
            }
          }
        }
      }
    }

    .courses {
      margin-top: 136px;

      .title-area {
        text-align: center;

        h3 {
          font-family: $satoshi;
          font-style: normal;
          font-weight: 900;
          font-size: 36px;
          line-height: 48px;
        }

        h4 {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
          color: #68798B;
        }
      }

      .courses-wrapper {
        margin-top: 32px;

        .course-item {
          text-decoration: none;

          .image-area {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 160px;
            border-radius: 20px 20px 0 0;
            background-color: #CFE7FD;

            .duration {
              border: 1px solid #AFC1F3;
              padding: 6px 12px;
              font-family: $roboto;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              color: $white;
              border-radius: 8px;
              position: absolute;
              top: 20px;
              right: 20px;
            }

            img {
              height: 100%;
              padding: 1px;
              max-width: 100%;
              border-radius: 20px 20px 0 0;
            }
          }

          .content-area {
            background: #EDF6FF;
            padding: 20px;
            min-height: 236px;
            border-radius: 0 0 20px 20px;

            h6 {
              font-family: $popins;
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 24px;
              color: #02264C;
              margin-bottom: 20px;
            }

            ul {
              li {
                font-family: $roboto;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #02264C;

              }

              &:not(:last-child) {
                margin-bottom: 20px;
              }
            }

            @include media-breakpoint-down(sm) {
              min-height: auto;
            }
          }
        }
      }
    }

    .why-gpt {
      margin-top: 84px;

      .custom-box {
        padding: 20px;

        .content-area {
          padding: 62px 80px;

          h5 {
            font-family: $satoshi;
            font-style: normal;
            font-weight: 900;
            font-size: 36px;
            line-height: 48px;
            color: #02264C;
            margin-bottom: 20px;
          }

          p {
            font-family: $roboto;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #02264C;
          }

          @include media-breakpoint-down(md) {
            padding: 20px;
          }
        }

        .video-area {
          height: 100%;
          background-color: #CFE7FD;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 426px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
            box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
          }
        }

        @include media-breakpoint-down(sm) {
          padding: 10px;
        }
      }

    }

    .whyme-gpt {
      margin-top: 84px;

      .custom-box {
        padding: 20px;

        .content-area {
          padding: 10px 10px;

          h5 {
            font-family: $satoshi;
            font-style: normal;
            font-weight: 900;
            font-size: 36px;
            line-height: 48px;
            color: #02264C;
            margin-bottom: 20px;


            .for-you {
              color: #ff694e;
              text-transform: uppercase;
            }
          }

          .then {
            margin-top: 1rem;
          }


          p {
            font-family: $roboto;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #02264C;

            .text-focus {
              color: #ff694e;
              font-weight: bold;

            }

          }

        }
      }

    }

    .testimonial {
      margin-top: 58px;
      transition: all .3s;

      .title-area {
        text-align: center;

        h3 {
          font-family: $satoshi;
          font-style: normal;
          font-weight: 900;
          font-size: 36px;
          line-height: 48px;
        }

        h4 {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
          color: #68798B;
        }
      }

      .testimonial-wrapper {
        margin-top: 32px;
        margin-bottom: 58px;

        .action-btn {
          border: 1px solid #DFE3E6;
          height: 48px;
          width: 48px;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;

          &:not(:disabled) {
            svg {
              path {
                fill: #02264C;
              }
            }
          }

          &:disabled {
            svg {
              path {
                fill: #ccc;
              }
            }
          }

          @include media-breakpoint-down(sm) {
            margin: 20px auto;
          }
        }

        .mySwiper2 {
          margin-bottom: 32px;

          .swiper-slide {
            font-family: $roboto;
            font-style: italic;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #02264C;
          }
        }

        .mySwiper {
          .swiper-slide {
            transition: 0.5s ease-in-out;
            -webkit-transition: 0.5s ease-in-out;
            border-top: solid 2px #DFE3E6;
            padding-top: 20px;

            h5 {
              font-family: $roboto;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              color: #02264C;
              margin-bottom: 4px;
            }

            h6 {
              font-family: $roboto;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #02264C;
              margin-bottom: 0;
            }

            &.swiper-slide-thumb-active {
              border-top-color: #4167DA;
            }

          }
        }
      }
    }

    .faq {
      .title-area {
        text-align: center;

        h3 {
          font-family: $satoshi;
          font-style: normal;
          font-weight: 900;
          font-size: 36px;
          line-height: 48px;
        }

        h4 {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
          color: #68798B;
        }
      }

      .accordion-item {
        background: #EEF1FD;
        border-radius: 20px;
        padding: 32px;
        border: none;

        h3 {
          font-family: $roboto;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          color: #02264C;

          @include media-breakpoint-down(sm) {
            width: 70%;
          }
        }

        .toggle-icon {
          height: 48px;
          width: 48px;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #FFF;

          @include media-breakpoint-down(sm) {
            min-width: 48px;
            min-height: 48px;
            max-width: 48px;
            max-height: 48px;
          }
        }

        @include media-breakpoint-down(sm) {
          padding: 15px;
        }
      }

      .accordion-content {
        margin-top: 20px;

        p {
          font-family: $roboto;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #02264C;
        }
      }
    }

    footer {
      padding: {
        top: 60px;
        bottom: 10px;
      }

      .logo {
        a {
          text-decoration: none;
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 15px;

          .logo-content {
            .title {
              font-family: $satoshi;
              font-style: normal;
              font-weight: 900;
              font-size: 18px;
              line-height: 24px;
              color: #02264C;
            }

            .sub-title {
              font-family: $roboto;
              font-style: normal;
              font-weight: 700;
              font-size: 12px;
              line-height: 18px;
              color: #68798B;
            }
          }
        }

        img {
          max-width: 60px;
          border: 2px solid #eef1fd;
          border-radius: 50%;
        }
      }

      .description {
        width: 70%;
        margin-bottom: 32px;

        p {
          font-family: $roboto;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #02264C;
        }
      }

      .locale-changer {
        .dropdown-toggle {
          box-shadow: none;
          outline: none;
          border: none;
        }
      }

      .email_to_href {
        a:link {
          text-decoration: none;
          color: black;
        }

        a:visited {
          text-decoration: none;
          color: black;
        }

        a:hover {
          text-decoration: none;
          color: black;
        }

        a:active {
          text-decoration: none;
          color: black;
        }
      }
    }

    .confirm-page {
      p {
        margin-bottom: 5px;
      }

      .success {
        img {
          margin: auto;
          display: block;
          width: 20%;
          margin-bottom: 3rem;
        }
      }

      .failed {
        img {
          margin: auto;
          display: block;
          width: 20%;
          margin-bottom: 3rem;
        }
      }
    }

    .tos-page {
      div {
        margin-top: 5rem;
        margin-bottom: 5rem;

        h1 {
          margin-bottom: 2rem;
          text-align: center;
        }

        h2 {
          font-weight: bold;
          font-size: 90%;
        }

        p {
          font-size: 80%;
        }
      }
    }

    .whatsapp-chatbot {

      position: fixed;
      bottom: 20px;
      right: 20px;
      z-index: 1000;

      .chat-bot-toggle {
        position: relative;
        background-color: #25D366;
        border-radius: 50%;
        padding: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

      }

      .chat-bot {
        position: fixed;
        right: 20px;
        bottom: 80px;
        width: 300px;
        background-color: #f8f8f8;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 20px;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
        transform: translateY(100%);
        transition: transform 0.3s ease-out;
        z-index: 1000;

        .chat-header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;

          h4 {
            margin: 0;
            color: #444;
          }

          .close-button {
            border: none;
            background: none;
            cursor: pointer;
            color: #444;
          }
        }

        textarea {
          width: 100%;
          height: 150px;
          border: none;
          border-radius: 10px;
          padding: 10px;
          margin-bottom: 10px;
          box-sizing: border-box;
          resize: none;
          outline: none;
        }

        .send-button {
          background-color: #24d366;
          color: white;
          border: none;
          border-radius: 5px;
          padding: 10px 20px;
          cursor: pointer;
          width: 100%;
          text-align: center;

          &:hover {
            background-color: #24d366;
          }
        }
      }

      .chat-bot.open {
        transform: translateY(0);
      }

      .chat-bot-toggle {
        position: fixed;
        right: 20px;
        bottom: 20px;
        background-color: #24d366;
        color: white;
        border: none;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        font-size: 24px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
        transition: background-color 0.3s ease-out;
        z-index: 1000;

        &:hover {
          background-color: #24d366;
        }
      }
    }

    .conferences {
      h1 {
        margin-top: 5rem;
        text-align: center;
        margin-bottom: 2rem;
      }

      .description {
        margin-top: 2rem;
        margin-bottom: 2rem;

        p {
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }

      .btn-animate:hover {
        animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
      }

      @keyframes shake {
        10%, 90% {
          transform: translate3d(-1px, 0, 0);
        }

        20%, 80% {
          transform: translate3d(2px, 0, 0);
        }

        30%, 50%, 70% {
          transform: translate3d(-4px, 0, 0);
        }

        40%, 60% {
          transform: translate3d(4px, 0, 0);
        }
      }

    }

  }


}

/* customize primary dark */
[data-bs-theme="dark"] {
  --bs-body-color: #{"#ffffff"};

  .dropdown-menu {
    --bs-dropdown-color: #fff;
    --bs-dropdown-bg: #68798B;

    .dropdown-item {
      --bs-dropdown-link-color: #{$white};
    }
  }

  .btn {
    --bs-btn-color: #{"#ffffff"};
  }

  .App {
    background-color: #0F1720;

    header {
      background-color: $dark-bg;

      .header-wrapper {
        .logo {
          a {
            .logo-content {
              .title {
                color: #EAF6FF;
              }
            }
          }
        }
      }
    }

    .plans {
      color: #EAF6FF;

      .custom-box {

        .billed-mode {
          background: #112B4B;

          .mode {
            &:not(.active) {
              color: #68798B;
            }

            &.active {
              background: #103966;
              color: #EAF6FF;
            }
          }
        }

        .plan-items-wrapper {

          .plan-item {
            background: #101B2C;
            border: 1px solid #103966;

            .team-size {
              span {
                color: #EEF1FD;
              }
            }


            .prices {


              ul {

                li {

                  &:not(.active) {
                    color: #68798B;

                  }

                  &.active {
                    color: #EEF1FD;
                  }

                  &.discount-badge {
                    background: #11253D;
                    border: 1px solid #103966;
                    color: #859FFC;
                  }
                }
              }
            }

            .includes {
              ul {
                li {
                  span {
                    color: #EEF1FD;
                  }
                }
              }
            }

            .action {
              .btn {
                color: #859FFC;
                background: #19223F;
              }
            }

            &:hover,
            &.active {
              background: #11253D;
            }
          }
        }
      }
    }

    .features {

      .feature-items-wrapper {


        .feature-item {


          .icon-box {

            background: #11253D;
          }

          .content {
            h6 {
              color: #EAF6FF;
            }

            p {
              color: #EAF6FF;
            }
          }
        }
      }
    }

    .courses {

      .courses-wrapper {


        .course-item {


          .image-area {
            background-color: #11253D;

            .duration {
              border: 1px solid #284087;
              color: #859FFC;
            }
          }

          .content-area {
            background: #101B2C;

            h6 {
              color: #EAF6FF;
            }

            ul {
              li {
                color: #EAF6FF;

              }
            }
          }
        }
      }
    }

    .why-gpt {

      .custom-box {

        .content-area {

          h5 {
            color: #EAF6FF;
          }

          p {

            color: #EAF6FF;
          }
        }

        .video-area {
          background-color: #112B4B;
        }
      }

    }

    .whyme-gpt {

      .custom-box {

        .content-area {

          h5 {
            color: #EAF6FF;
          }

          p {

            color: #EAF6FF;
          }
        }

      }

    }

    .testimonial {
      margin-top: 58px;

      .title-area {
        text-align: center;

        h3 {
          font-family: $satoshi;
          font-style: normal;
          font-weight: 900;
          font-size: 36px;
          line-height: 48px;
        }

        h4 {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
          color: #68798B;
        }
      }

      .testimonial-wrapper {
        .action-btn {
          border-color: #103966;


          &:not(:disabled) {
            svg {
              path {
                fill: #EAF6FF;
              }
            }
          }

          &:disabled {
            svg {
              path {
                fill: #333333;
              }
            }
          }
        }

        .mySwiper2 {
          .swiper-slide {
            color: #EAF6FF;
          }
        }

        .mySwiper {
          .swiper-slide {
            border-top: solid 2px #103966;

            h5 {
              color: #EAF6FF;
            }

            h6 {
              color: #EAF6FF;
            }
          }
        }
      }
    }

    .faq {
      .accordion-item {
        background: #11253D;

        h3 {
          color: #EAF6FF;
        }

        .toggle-icon {
          background-color: #0F1720;

          svg {
            path {
              fill: #EAF6FF;
            }
          }
        }
      }

      .accordion-content {
        p {
          color: #EAF6FF
        }
      }
    }

    footer {
      .logo {
        a {
          .logo-content {
            .title {
              color: #EAF6FF;
            }

            .sub-title {
              color: #68798B;
            }
          }
        }
      }

      .description {
        p {
          color: #68798B;
        }
      }

      .socials {
        li {
          a {
            background: #11253D;
          }
        }
      }

      .rss {
        h6 {
          color: #EAF6FF;
        }

        .email-input {
          background: #11253D;
        }

        .form-text {
          color: #68798B;
        }
      }
    }

    .tos-page {
      div {
        margin-top: 5rem;
        margin-bottom: 5rem;

        h1 {
          margin-bottom: 2rem;
          text-align: center;
        }

        h2 {
          font-weight: bold;
          font-size: 90%;
        }

        p {
          font-size: 80%;
        }
      }
    }

    .whatsapp-chatbot {

      position: fixed;
      bottom: 20px;
      right: 20px;
      z-index: 1000;

      .chat-bot-toggle {
        position: relative;
        background-color: #25D366;
        border-radius: 50%;
        padding: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

      }

      .chat-bot {
        position: fixed;
        right: 20px;
        bottom: 80px;
        width: 300px;
        background-color: #f8f8f8;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 20px;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
        transform: translateY(100%);
        transition: transform 0.3s ease-out;
        z-index: 1000;

        .chat-header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          color: #757575;

          h4 {
            margin: 0;
            color: #444;
          }

          .close-button {
            border: none;
            background: none;
            cursor: pointer;
            color: #444;
          }
        }

        textarea {
          width: 100%;
          height: 150px;
          border: none;
          border-radius: 10px;
          padding: 10px;
          margin-bottom: 10px;
          box-sizing: border-box;
          resize: none;
          outline: none;
        }

        .send-button {
          background-color: #24d366;
          color: white;
          border: none;
          border-radius: 5px;
          padding: 10px 20px;
          cursor: pointer;
          width: 100%;
          text-align: center;

          &:hover {
            background-color: #24d366;
          }
        }
      }

      .chat-bot.open {
        transform: translateY(0);
      }

      .chat-bot-toggle {
        position: fixed;
        right: 20px;
        bottom: 20px;
        background-color: #24d366;
        color: white;
        border: none;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        font-size: 24px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
        transition: background-color 0.3s ease-out;
        z-index: 1000;

        &:hover {
          background-color: #24d366;
        }
      }
    }
  }

  .custom-btn {
    background: linear-gradient(0deg, #19223F, #19223F), #FFFFFF;
    color: #859FFC;
  }

  .custom-box {
    background: #101B2C;
    border-radius: 32px;
  }

}